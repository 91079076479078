<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="countUnread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ countUnread }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
<!--      <b-link
        v-for="notification in notifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>-->

      <!-- System Notification Toggler -->
<!--      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>-->

      <!-- System Notifications -->
      <b-link
        v-for="(notification,key) in notifications"
        :key="key" :href="notification.route_name"
      >
        <b-media @click="read(notification.id)">
          <template #aside>
            <b-avatar
              size="32"
              variant="light-success"
            >
              <feather-icon :icon="'N'" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.sub_title }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
<!--    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    ></b-button>
    </li>-->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar,//BButton
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import apiCall from "@/libs/axios";
import useJwt from "@/auth/jwt/useJwt";
import {initialAbility} from "@/libs/acl/config";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,//BButton,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings:{},
    }
  },
  mounted() {
    this.$store.dispatch('GET_ALL_NOTIFICATION');
  },
  methods: {
    read(id){
      apiCall.put(`/my/notification/seen${id}`).then(()=>{
        this.$store.dispatch('GET_ALL_NOTIFICATION');
      });
    }
  },
  computed:{
    countUnread(){
      return (this.notifications.filter((item)=>item.is_read===0 )).length;
    },
    notifications(){
      return this.$store.getters.notifications;
    }
  },
  watch:{
    /* eslint-disable global-require */
    $route (){
     // console.log(to+from);
      if(!this.$cookie.get(useJwt.jwtConfig.storageTokenKeyName)){
        localStorage.removeItem('userData')
        // Reset ability
        this.$ability.update(initialAbility)
        // Redirect to login page
        this.$router.push({ name: 'login' })
      }
      this.$store.dispatch('GET_ALL_NOTIFICATION');
    },
    /* eslint-disable global-require */
    notifications(){
      this.perfectScrollbarSettings={
        maxScrollbarLength: 60,
        wheelPropagation: false,
      };
    }
  }
}
</script>

<style>

</style>
