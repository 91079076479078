export default [
    {
        header: 'Main Panel',
        action: 'read',
        resource: 'Main Panel',
    },
    {
        title: 'dashboard',
        route: 'home',
        icon: 'HomeIcon',
        action: 'read',
        resource: 'Dashboard',
    },
    {
        title: 'Master Settings',
        icon: 'SettingsIcon',
        children: [
            {
                title: 'Institute',
                route: 'institute',
                icon: 'DropletIcon',
                action: 'read',
                resource: 'Institute',
            },
            {
                action: 'read',
                resource: 'Version',
                title: 'Versions',
                route: 'version',
            },
            {
                action: 'read',
                resource: 'Shift',
                title: 'Shifts',
                route: 'shift',
            },
            {
                action: 'read',
                resource: 'Wing',
                title: 'Wings',
                route: 'wing',
            },
            {
                action: 'read',
                resource: 'Section',
                title: 'Sections',
                route: 'section',
            },
            {
                action: 'read',
                resource: 'Student Group',
                title: 'Student Groups',
                route: 'student-group',
            },
            
            {
                action: 'read',
                resource: 'Academic Year',
                title: 'Academic Years',
                route: 'academic-year',
            },
            {
                action: 'read',
                resource: 'Religion',
                title: 'Religions',
                route: 'religion',
            },
            
        ]
    },
    {
        title: 'Class Management',
        icon: 'TypeIcon',
        children:[
            {
                action: 'read',
                resource: 'Classes',
                title: 'Current Classes',
                route: 'classes',
            },
            {
                action: 'create',
                resource: 'Classes',
                title: 'Add New Class',
                route: 'class-add',
            },
            {
                action: 'read',
                resource: 'Map Section',
                title: 'Map Sections',
                route: 'wing-section-add',
            },
            {
                action: 'read',
                resource: 'Wings Vs. Section',
                title: 'Wings Vs. Sections',
                route: 'wing-section',
            },
            {
                action: 'read',
                resource: 'Book List',
                title: 'Book List',
                route: 'book-list',
            },
            {
                action: 'read',
                resource: 'Syllabus',
                title: 'Syllabus',
                route: 'syllabus',
            },
            {
                action: 'read',
                resource: 'Class Teacher',
                title: 'Class Teacher',
                route: 'class-teacher',
            },
            {
                action: 'read',
                resource: 'Subject Teacher',
                title: 'Subject Teachers',
                route: 'subject-teacher',
            },
        ],
    },
    {
        title: 'Manage Subject',
        icon: 'BookIcon',
        resource: 'Subject',
        children: [
            {
                title: 'Add Subject',
                route: 'add-subject',
                action: 'create',
                resource: 'Subject',
            },
            {
                title: 'Subject List',
                route: 'subject',
                action: 'read',
                resource: 'Subject',
            },
        ]
    },
    {
        title: 'Routines',
        icon: 'CalendarIcon',
        children: [
            {
                action: 'read',
                resource: 'Class Day',
                title: 'Class Days',
                route: 'class-day',
            },
            {
                action: 'read',
                resource: 'Time Slot',
                title: 'Time Slot',
                route: 'class-period',
            },
            {
                action: 'create',
                resource: 'Class Routine',
                title: 'Add Routine',
                route: 'class-routine-add',
            },
            {
                action: 'read',
                resource: 'Class Routine',
                title: 'Class Routines',
                route: 'class-routine',
            },
            {
                action: 'read',
                resource: 'Exam Routine',
                title: 'Exam Routines',
                route: 'exam-routine',
            },
        ]
    },
    {
        title: 'System User',
        icon: 'UserIcon',
        resource: 'User',
        children: [
            {
                title: 'Role & Permission',
                route: 'role-permission',
                icon: 'HomeIcon',
                action: 'read',
                resource: 'Permission',
            },
            {
                title: 'User & Permission',
                route: 'user-permission',
                icon: 'HomeIcon',
                action: 'read',
                resource: 'Permission',
            },
            {
                action: 'read',
                resource: 'Reset Password',
                title: 'Reset Password',
                route: 'reset-password',
            },
        ]
    },
    {
        title: 'Exam Management',
        icon: 'BookIcon',
        children: [
            {
                action: 'read',
                resource: 'Grade Manage',
                title: 'Grade Manage',
                route: 'grade-type',
            },
            {
                action: 'read',
                resource: 'Exam Type',
                title: 'Exam Type',
                route: 'exam-type',
            },
            {
                action: 'read',
                resource: 'Mark Distribution',
                title: 'Mark Distribution',
                route: 'mark-distribution',
            },
            {
                action: 'read',
                resource: 'Allow Mark Upload',
                title: 'Allow Mark Upload',
                route: 'allow-mark-upload'
            },
        ]
    },
    {
        title: 'Process Result',
        icon: 'BookIcon',
        children: [
            {
                action: 'generate',
                resource: 'Result Processing',
                title: 'Generate Result',
                route: 'result-generate',
            },
            {
                action: 'publish',
                resource: 'Result Processing',
                title: 'Result Publish',
                route: 'result-publish',
            },
            {
                action: 'all result',
                resource: 'Result Processing',
                title: 'Mark Sheet',
                route: 'all-result',
            },
            {
                action: 'result by subject',
                resource: 'Result Processing',
                title: 'Result by Subject',
                route: 'result-by-subject',
            },
            {
                action: 'result by subject',
                resource: 'Result Processing',
                title: 'Combined Result',
                route: 'result-by-part',
            },
            {
                action: 'all result',
                resource: 'Result Processing',
                title: 'Total Mark Summary',
                route: 'summary-of-total',
            },
            {
                action: 'all result',
                resource: 'Result Processing',
                title: 'Summary of GP',
                route: 'summary-of-grade-result',
            },
            {
                action: 'all result',
                resource: 'Result Processing',
                title: 'S. of GP & Total',
                route: 'summary-of-grade-total-result',
            },
        ]
    },
    {
        title: 'Fees Management',
        icon: 'BriefcaseIcon',
        children: [
            {
                action: 'read',
                resource: 'Payment Option',
                title: 'Payment Options',
                route: 'student-fee-option',
            },
            {
                action: 'read',
                resource: 'Fee Head',
                title: 'Fee Head',
                route: 'student-fee-head',
            },
            {
                action: 'read',
                resource: 'Fee Type',
                title: 'Fee Types',
                route: 'student-fee-type',
            },
            {
                action: 'read',
                resource: 'Assigned Fee',
                title: 'Assigned Fees',
                route: 'assign-student-fee',
            },
            {
                action: 'read',
                resource: 'Assign Canteen Bill',
                title: 'Assign Canteen Bills',
                route: 'assign-canteen-bill',
            },
        ]
    },
    {
        title: 'Students',
        icon: 'UserIcon',
        children: [
            {
                action: 'create',
                resource: 'Student',
                title: 'Add Student',
                route: 'add-student',
            },
            {
                action: 'read',
                resource: 'Student',
                title: 'Student List',
                route: 'students',
            },
            {
                action: 'read',
                resource: 'Parent',
                title: 'Parent List',
                route: 'student.parent',
            },
            {
                action: 'read',
                resource: 'Group Subject',
                title: 'Group Subject',
                route: 'student.group.subject',
            },
            {
                action: 'read',
                resource: '4th subject',
                title: '4th Subject Manage',
                route: 'student.fourth.subject',
            },
            {
                action: 'promotion',
                resource: 'Student',
                title: 'Student Promotion',
                route: 'single.student.promotion',
            },
            {
                action: 'promotion',
                resource: 'Student',
                title: 'Class Promotion',
                route: 'class.student.promotion',
            },
            {
                action: 'tc',
                resource: 'Student',
                title: 'Student TC',
                route: 'student.tc',
            },
        ]
    },
    
    {
        title: 'Student Waiver',
        icon: 'BriefcaseIcon',
        children: [
            {
                action: 'read',
                resource: 'Waiver Type',
                title: 'Waiver Type',
                route: 'student-waiver-type',
            },
            {
                action: 'read',
                resource: 'Waiver',
                title: 'Waivers',
                route: 'student-waiver',
            },
            {
                action: 'read',
                resource: 'Waiver For All',
                title: 'Waiver For All',
                route: 'student-waiver-for-all',
            },
        ]
    },
    {
        title: 'Employee',
        icon: 'BriefcaseIcon',
        children: [
            {
                action: 'read',
                resource: 'Department',
                title: 'Departments',
                route: 'employee-department',
            },
            {
                action: 'read',
                resource: 'Designation',
                title: 'Designations',
                route: 'employee-designation',
            },
            {
                action: 'read',
                resource: 'Scale',
                title: 'Scales',
                route: 'employee-scale',
            },
            {
                action: 'read',
                resource: 'Designation & Scale',
                title: 'Designation & Scales',
                route: 'employee-designation-scale',
            },
            {
                action: 'read',
                resource: 'Employee',
                title: 'Employees',
                route: 'employee',
            },
            {
                action: 'read',
                resource: 'Bonus Type',
                title: 'Bonus Types',
                route: 'employee-bonus-type',
            },
            {
                action: 'read',
                resource: 'Employee Bonus',
                title: 'Employee Bonus',
                route: 'employee-bonus',
            },
            {
                action: 'read',
                resource: 'Salary',
                title: 'Salaries',
                route: 'employee-salary',
            },
        ]
    },
    {
        title: 'Payment Settings',
        icon: 'DollarSignIcon',
        children: [
            {
                action: 'read',
                resource: 'Payment Type',
                title: 'Payment Type',
                route: 'payment-type',
            },
            {
                action: 'take',
                resource: 'Payment',
                title: 'Take Payment',
                route: 'take-student-payment',
            },
            {
                action: 'read',
                resource: 'Payment',
                title: 'Student Payment',
                route: 'student-payment',
            },
        ]
    },
    {
        title: 'Bank & Accounts',
        icon: 'DatabaseIcon',
        children: [
            {
                action: 'read',
                resource: 'Bank',
                title: 'Banks',
                route: 'bank',
            },
            {
                action: 'read',
                resource: 'Account',
                title: 'Bank Accounts',
                route: 'bank-account',
            },
        ]
    },
    {
        title: 'Attendance',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'read',
                resource: 'Employee Attendance',
                title: 'Employee Attendance',
                route: 'employee-global-attendance',
            },
            {
                action: 'read',
                resource: 'Employee Leave Request',
                title: 'Employee Leave Request',
                route: 'employee-leave-request',
            },
            {
                action: 'read',
                resource: 'Student Attendance',
                title: 'Student Attendance',
                route: 'student-global-attendance',
            },
        ]
    },
]