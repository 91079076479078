import public_site from "@/navigation/vertical/public_site";
import main_panel from "@/navigation/vertical/main_panel";
import employee_panel from "@/navigation/vertical/employee_panel";
import student_panel from "@/navigation/vertical/student_panel";
import parent_panel from "@/navigation/vertical/parent_panel";
export default [
    ...main_panel,
    ...employee_panel,
    ...student_panel,
    ...parent_panel,
    ...public_site,
]
