export default [
    {
        header: 'Employee Panel',
        action: 'employee',
        resource: 'Employee',
    },
    {
        title: 'Student Task',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'home work',
                resource: 'Employee Task',
                title: 'Home Works',
                route: 'employee-task-homework',
            },
            {
                action: 'course material',
                resource: 'Employee Task',
                title: 'Course Materials',
                route: 'employee-task-course-material',
            },
            {
                action: 'attendance',
                resource: 'Employee Task',
                title: 'Attendance',
                route: 'employee-task-attendance',
            },
            {
                action: 'mark upload',
                resource: 'Employee Task',
                title: 'Mark Upload',
                route: 'employee-task-mark-upload',
            },
        ]
    },
    // {
    //     title: 'Personal',
    //     icon: 'ClipboardIcon',
    //     children: [
    //         {
    //             action: 'salary',
    //             resource: 'Employee Personal',
    //             title: 'Salary',
    //             route: 'employee-personal-salary',
    //         },
    //         {
    //             action: 'provident fund',
    //             resource: 'Employee Personal',
    //             title: 'Provident Fund',
    //             route: 'employee-personal-fund',
    //         },
    //         {
    //             action: 'loan',
    //             resource: 'Employee Personal',
    //             title: 'Loan',
    //             route: 'employee-personal-loan',
    //         },
    //         {
    //             action: 'attendance',
    //             resource: 'Employee Personal',
    //             title: 'Attendance',
    //             route: 'employee-personal-attendance',
    //         },
    //         {
    //             action: 'leave request',
    //             resource: 'Employee Personal',
    //             title: 'Leave Request',
    //             route: 'employee-personal-leave-request',
    //         },
    //     ]
    // },
]