export default [
    {
        header: 'Student Panel',
        action: 'student',
        resource: 'Student',
    },
    {
        title: 'My Subjects',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'subject',
                resource: 'Student',
                title: 'Subject',
                route: 'student-personal-subject',
            },
        ]
    },
    {
        title: 'My Routines',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'class routine',
                resource: 'Student',
                title: 'Class Routine',
                route: 'student-personal-class-routine',
            },
            {
                action: 'exam routine',
                resource: 'Student',
                title: 'Exam Routine',
                route: 'student-personal-exam-routine',
            },
        ]
    },
    {
        title: 'My Task',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'homework',
                resource: 'Student',
                title: 'Home Works',
                route: 'student-personal-home-works',
            },
            {
                action: 'course material',
                resource: 'Student',
                title: 'Course Materials',
                route: 'student-personal-course-material',
            }
        ]
    },
    {
        title: 'My Attendance',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'attendance',
                resource: 'Student',
                title: 'Attendance',
                route: 'student-personal-attendance',
            },
            {
                action: 'leave request',
                resource: 'Student',
                title: 'Leave Request',
                route: 'student-personal-leave-request',
            }
        ]
    },
    {
        title: 'My Result',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'result',
                resource: 'Student',
                title: 'Result',
                route: 'student-personal-results',
            },
        ]
    },
    {
        title: 'My Payments',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'payment',
                resource: 'Student',
                title: 'Payment',
                route: 'student-personal-payment',
            },
            {
                action: 'payment history',
                resource: 'Student',
                title: 'Payment History',
                route: 'student-personal-payment-history',
            },
        ]
    },
]