export default [
    {
        header: 'Parent Panel',
        action: 'parent',
        resource: 'Parent',
    },
    {
        title: 'Children',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'children',
                resource: 'Parent',
                title: 'Children',
                route: 'parent-child',
            },
        ]
    },
    {
        title: 'Children Subjects',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'subject',
                resource: 'Parent',
                title: 'Subject',
                route: 'parent-subject',
            },
        ]
    },
    {
        title: 'Children Task',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'homework',
                resource: 'Parent',
                title: 'Home Works',
                route: 'parent-homework',
            },
            {
                action: 'course material',
                resource: 'Parent',
                title: 'Course Materials',
                route: 'parent-course-material',
            }
        ]
    },
    {
        title: 'Children Attendance',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'attendance',
                resource: 'Parent',
                title: 'Attendance',
                route: 'parent-child-attendance',
            },
        ]
    },
    {
        title: 'Children Result',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'result',
                resource: 'Parent',
                title: 'Result',
                route: 'parent-child-results',
            },
        ]
    },
    {
        title: 'Children Payments',
        icon: 'ClipboardIcon',
        children: [
            {
                action: 'payment',
                resource: 'Parent',
                title: 'Payment',
                route: 'parent-child-payment',
            },
            {
                action: 'payment history',
                resource: 'Parent',
                title: 'Payment history',
                route: 'parent-child-payment-history',
            },
        ]
    },
]